export const languageLoader = {
	ar: () => import('dayjs/locale/ar'),
	bg: () => import('dayjs/locale/bg'),
	cs: () => import('dayjs/locale/cs'),
	da: () => import('dayjs/locale/da'),
	de: () => import('dayjs/locale/de'),
	el: () => import('dayjs/locale/el'),
	es: () => import('dayjs/locale/es'),
	fi: () => import('dayjs/locale/fi'),
	fr: () => import('dayjs/locale/fr'),
	he: () => import('dayjs/locale/he'),
	hr: () => import('dayjs/locale/hr'),
	hu: () => import('dayjs/locale/hu'),
	id: () => import('dayjs/locale/id'),
	it: () => import('dayjs/locale/it'),
	ja: () => import('dayjs/locale/ja'),
	ko: () => import('dayjs/locale/ko'),
	lv: () => import('dayjs/locale/lv'),
	ms: () => import('dayjs/locale/ms'),
	nb: () => import('dayjs/locale/nb'),
	nl: () => import('dayjs/locale/nl'),
	pl: () => import('dayjs/locale/pl'),
	pt: () => import('dayjs/locale/pt'),
	ro: () => import('dayjs/locale/ro'),
	sk: () => import('dayjs/locale/sk'),
	sl: () => import('dayjs/locale/sl'),
	sr: () => import('dayjs/locale/sr'),
	sv: () => import('dayjs/locale/sv'),
	th: () => import('dayjs/locale/th'),
	tr: () => import('dayjs/locale/tr'),
	uk: () => import('dayjs/locale/uk'),
	zh: () => import('dayjs/locale/zh')
};
